import {log_metric_route} from './constants';
import {getAnalytics, logEvent} from 'firebase/analytics';
import {initializeApp} from "firebase/app";

const firebaseConfig = {
    apiKey: 'AIzaSyBjEjSEuuUdwSmQOmgBYMvlD3OEym_GYDM',
    authDomain: 'war-and-peace---web-shop.firebaseapp.com',
    projectId: 'war-and-peace---web-shop',
    storageBucket: 'war-and-peace---web-shop.appspot.com',
    messagingSenderId: '641891616829',
    appId: '1:641891616829:web:6eef707ec3ea2f282c4254',
    measurementId: 'G-PQ99NRKBXW',
};

const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);



export const logMetric = async (value) => {
    try {

        const level = 'info'; // Possible values: 'debug', 'info', 'warning', 'error', 'critical'
        Rollbar.captureEvent(value, level);
        logEvent(analytics, JSON.stringify(value));
        
        const response = await fetch(log_metric_route, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(value)
        });

        if (!response.ok) {
            const message = `An error has occured: ${response.status}`;
            Rollbar.error(`An error has occured: ${response.status}`);
            throw new Error(message);
        }

        return await response.text();

    } catch (error) {
        Rollbar.error("Log metric error: " + error);
    }
};